<template>
  <div class="tw-w-full">
    <Loader :loading="loading" v-if="loading"/>
<div class="dashboard" v-else>
  <div class="action-cards tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between tw-mt-16 lg:tw-mt-0" >
    <v-col sm="12" lg="3" >
      <v-card class="wallet-balance tw-w-full" flat>
          <icons name="open-wallet" :color="companyData.companyColor"/>
        <div class="mt-2 space">
          <span class="subtext">Your wallet balance is</span>
          <p class="amount mt-1" >₦{{formatNumber(walletData.accountBalance)}}</p>
        </div>
      </v-card>
    </v-col>
    <v-col sm="12" lg="3">
      <v-card  class="spending tw-w-full" flat >
        <span class="font-size-18">Withdrawal</span>
        <div class="tw-flex mt-5">
        <v-col cols="2">
          <icons name="wallet-with-card" :color="companyData.companyColor" class="mt-2" width="44" height="44"/>
        </v-col>
        <v-col cols="10" class="ml-1 space">
          <p class="font-size-28 mt-2 mb-1">₦{{formatNumber(monthlySpending)}}</p>
          <p class="subtext">Withdrawal this month</p>
        </v-col>
        </div>
      </v-card>
    </v-col>
    <v-col sm="12" lg="2" class="">
      <v-card class="fund-wallet tw-w-full tw-hidden" flat  @click="handleCloseModal">
        <icons name="closed-wallet" :color="companyData.companyColor" class="mt-2 tw-hidden"/>
         <p class="subheading mt-2 tw-hidden"> Fund Wallet</p>
      </v-card>
      <v-card class="withdraw tw-w-full tw-flex tw-items-center tw-justify-center tw-flex-col" flat  @click="handleWithdrawalModal">
          <img src="../../assets/wallet-icons/withdraw.svg" alt="Add a booking icon" class="mt-2 withdrawal-icon"/>
          <p class="subheading mt-2">Withdraw from Wallet</p>
      </v-card>
    </v-col>
    <v-col sm="12" lg="3" >
      <v-card class="account-number tw-flex tw-w-full" flat>
        <v-col cols="5">
          <img src="../../assets/wallet-icons/bank.svg" alt="Bank icon" class="mt-2"/>
          <p class="subheading mt-2 mb-1">Account Number</p>
        </v-col>
        <div class="middle-line"></div>
        <v-col cols="7" class="ml-2 display-center-with-flex" v-if="walletData.kudaAccount">
          <h6 class="digits">{{walletData.kudaAccount.trackingReference}}</h6>
        </v-col>

      </v-card>
    </v-col>


  </div>

  <div class="transaction-history mt-6">
    <h5 class="heading font-size-18">Transactions</h5>
    <div class="grouped-cards mt-5 tw-flex-wrap tw-flex tw-gap-5">
      <v-card  flat  v-for="(transaction, index) in walletData.transactions" :key="index" @click="handleClick(transaction)">
        <div class="topmost tw-flex tw-capitalize">
          <span :style="{color : companyData.companyColor}" class="credit" v-if="transaction.paymentType === 'CREDIT'">Credit</span>
          <span class="debit" v-if="transaction.paymentType === 'DEBIT'">Debit</span>
          <span class="bonus" v-if="transaction.paymentType === 'BONUS'">Bonus</span>
          <span class="reverse" v-if="transaction.paymentType === 'REVERSE'">Reversal</span>
          <v-spacer></v-spacer>
          <icons name="download"/>
        </div>
        <div class="middle-layer my-4 tw-flex">
          <p class="question"> Amount : <span class="answer">₦{{formatNumber(transaction.amount)}}</span></p>
          <v-spacer></v-spacer>
          <p class="question"> Reference ID : <span class="question text-uppercase">{{ transaction.reference }} </span></p>
        </div>
        <div class="second-middle-layer  tw-flex">
          <h6 class="question"> Desc : <span class="question">{{transaction.description}}</span></h6>
        </div>
        <v-divider class="my-3"></v-divider>
        <div class="bottom-layer tw-flex tw-justify-end">
          <span class="date"> {{ getDateTime(transaction.transactionDate) }}</span>
        </div>
      </v-card>
        <view-transaction :modal="dialog" @close="closeModal"/>
    </div>
  </div>
</div>

    <deposit-modal  :show-modal="showModal"
                header="Enter amount to deposit" width="500px"
                :color="companyData.companyColor" @closeModal="handleCloseModal" @action="handleShowPaymentModal" />

    <withdrawal-modal :color="companyData.companyColor"
                      :dialog="withdrawalModal"
                      @closeModal="handleWithdrawalModal" :wallet-balance="formatNumber(walletData.accountBalance)"
                     :loading="withdrawalLoading" @action="handleWithdrawal" :main-wallet="walletData"/>
    <paystack
        :amount="depositAmount*100"
        :email="companyData.companyEmail"
        :paystackkey="getPayStackKey"
        :reference="reference"
        :callback="handleVerifyPaysackPayment"
        :close="handleClosePaytackModal"
        :embed="false"
        :channels= "['card','bank_transfer']"
        :subaccount="walletData.paystackSubAccountCode"
    >
      <div class="tw-hidden" ref="paystack">Pay</div>
    </paystack>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ViewTransaction from "@/components/reuseables/ViewTransaction";
import {
  createPaymentLog, getTerminalWallet,
  makePayment,
  verifyPaystackPayment, withdrawFromWallet
} from "@/services/api/APIService";

import paystack from "vue-paystack"
import dayjs from "dayjs";
import WithdrawalModal from "@/components/reuseables/WithdrawalModal";
import Icons from "@/components/reuseables/Icons";
import Loader from "@/components/reuseables/Loader";
import DepositModal from "@/components/reuseables/DepositModal";

export default {
  name: "StaffWalletDashboard",
  components: {DepositModal, Loader, Icons, WithdrawalModal, ViewTransaction,paystack},
  data(){
    return{
      dialog: false,
      loading: true,
      walletData:{},
      showPaymentModal: false,
      paymentUrl : "",
      showModal : false,
      depositAmount : 0,
      paymentLoading : false,
      paymentReference: "",
      withdrawalModal: false,
      withdrawalLoading : false,
      monthlySpending: 0,
      reference:null
    }
  },
  computed:{
    ...mapGetters("companyProfile", ["companyData","subWallet"]),
    getPayStackKey(){
      return process.env.VUE_APP_PAYSTACK_LIVE_PUBLIC_KEY
    }
  },
  methods:{
    getReference(){
      let text = "";
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for( let i=0; i < 10; i++ )
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      this.reference = text;
    },
    handleWithdrawalModal(){
      this.withdrawalModal = !this.withdrawalModal
    },
    handleCloseModal(){
      this.showModal = !this.showModal
    },
    closePaymentModal(){
      this.showPaymentModal = false
      this.handleVerifyPaysackPayment()
    },
    handleClosePaytackModal(){},
    handleShowPaymentModal(depositAmount){
      if (!depositAmount){
        this.$displaySnackbar({
          message: "Deposit Amount cannot be empty",
          success: false,
        });
      }
      else {
        this.handleCloseModal()
        this.depositAmount = depositAmount
        this.createFundWalletPaymentLog(depositAmount)
      }
    },
    async createFundWalletPaymentLog(depositAmount){
      this.getReference()
      let paymentLog = {}
      paymentLog.amount = depositAmount
      paymentLog.currency="NGN"
      paymentLog.reference= this.reference
      paymentLog.service = "Npay"
      paymentLog.paymentProcessor = "paystack";
      paymentLog.purpose = "Fund TSP Wallet";
      paymentLog.userEmail = this.companyData.companyEmail;
      await  createPaymentLog(paymentLog).then(() =>{
        this.$refs.paystack.click()
        console.log("Done With creating Payment Log")
      })
    },

    handleWithdrawal(withdrawalAmount){
      if (!withdrawalAmount){
        this.$displaySnackbar({
          message: "Withdrawal Amount cannot be empty",
          success: false,
        });
      }
      else if(Number.parseFloat(withdrawalAmount) > Number.parseFloat(this.subWallet.accountBalance)){
        this.$displaySnackbar({
          message: "Withdrawal amount exceeded your wallet balance",
          success: false,
        });
      }
      else {
        this.withdrawalLoading = true
        let  data = {}
        data.userEmail = this.companyData.companyEmail
        data.userId = this.companyData.transportCompanyId
        data.amount = withdrawalAmount
        data.narration = "Wallet withdrawal"
        data.subWalletId = this.subWallet.id
        data.withdrawalType = "sub"
        withdrawFromWallet(data).then(() => {
          this.withdrawalLoading = false
          this.withdrawalModal = false
          this.getNpayTerminalWallet()
        }).catch((err) => {
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          });

        }).finally(()=> this.withdrawalLoading = false);
      }
    },

    async handlePayment(){
      // this.paymentLoading = true
      let data ={}
      data.email = this.companyData.companyEmail
      data.amount= this.depositAmount* Number.parseInt(process.env.VUE_APP_KOBO)
      data.channels= ['card']
      data.subaccount = this.walletData.paystackAccount
      data.percentage_charge =  Number.parseFloat(process.env.VUE_APP_CHARGERATE)
      data.bearer = this.walletData.paystackAccount
      data.callback =() =>{
        this.showPaymentModal = false
      }
      await makePayment(data).then(async res =>{
        this.paymentUrl= res.data.data.authorization_url
        this.showPaymentModal= true
        this.paymentReference = res.data.data.reference
      }).catch((err) => {
            this.$displaySnackbar({
              message: err.response.data.details[0],
              success: false,
            });
            this.paymentLoading = false;
          });
    },
    handleVerifyPaysackPayment(){
      let data = {
        "paystackData":{
          "reference":this.reference
        }
      }
      verifyPaystackPayment(data).then(() => {
        this.getNpayWallet()
      })
    },
    // handleVerifyPaysackPayment(){
    //   let userData = JSON.parse(sessionStorage.getItem("userData"))
    //   let userId = userData.id
    //     let data = {}
    //     data.departure = "-"
    //     data.destination = "-"
    //     data.selectedSeatNumbers = "-"
    //     data.amount= this.depositAmount
    //     data.paymentReference = this.reference
    //     data.receivingAccountName = this.companyData.paystackAccount.businessName
    //     data.receivingAccountNumber= this.companyData.paystackAccount.AccountNumber
    //     data.paymentProcessor= "Paystack"
    //     data.paymentPurpose = "Deposit"
    //     data.customerEmail= this.companyData.companyEmail
    //   verifyPaystackPayment(userId,this.reference,data).then(() => {
    //     this.getWallet()
    //   })
    // },

    handleClick(transaction) {
      this.$store.dispatch('walletStore/selectedTransaction', transaction)
      this.dialog = true;
    },
    closeModal(){
      this.dialog = false
    },
    getDateTime(date){
      return dayjs(date).format("LLL")
    },
    getMonthlySpending(transactions){
      this.monthlySpending = 0
      if (transactions) {
        transactions.forEach(transaction => {
          if (dayjs(transaction.transactionDate).month() === dayjs().month() && dayjs(transaction.transactionDate).year() === dayjs().year() && transaction.paymentType === 'DEBIT') {
            this.monthlySpending += transaction.amount
          }
        })
      }
    },
    formatNumber(number){
      if(number){
        return number.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
      }
      else {
        return 0;
      }
    },
    async getNpayTerminalWallet(){
      let staffData = JSON.parse(sessionStorage.getItem("staffData"));
      this.loading = true
      let data = {}
      data.userId = this.companyData.transportCompanyId;
      data.terminalAssignedId = staffData.terminalId;
      await getTerminalWallet(data).then(res => {
        this.loading = false
        this.walletData = res.data.subWallet
        this.$store.dispatch("companyProfile/setSubWallet",res.data.subWallet)
        this.getMonthlySpending(this.walletData.transactions)
        if(this.walletData.transactions) {
          this.walletData.transactions.sort(function (a, b) {
            return (
                dayjs(b.createdAt).toDate() -
                dayjs(a.createdAt ).toDate()
            );
          });
        }
      }).finally(()=> this.loading = false)
    }
  },
  async created() {
    await this.getNpayTerminalWallet()
  }
}
</script>

<style scoped lang="scss">
.wallet-balance, .spending{
  height: 182px;
  background: var(--offWhite);
  box-shadow: 0 4.03659px 40.3659px rgba(79, 79, 79, 0.02);
  border-radius: 15px;
  padding: 25px;
}
.subtext{
  font-family: var(--fontInter);
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: rgba(79, 79, 79, 0.62);
}
.wallet-balance .amount{
  font-family: var(--fontInter);
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: #001127;
}
.font-size-28{
  font-family: var(--fontInter);
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: #001127;

}
.font-size-18{
  font-family: var(--fontInter);
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: rgba(0, 17, 39, 0.75);
}
.withdraw, .fund-wallet, .account-number{
  height: 182px;
  background: var(--offWhite);
  box-shadow: 0px 4.03659px 40.3659px rgba(79, 79, 79, 0.02);
  border-radius: 15px;
  padding: 10px 30px;
}
.subheading{
  font-family: var(--fontInter);
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: var(--brown);
}
.sculptor{
  //display: flex;
  //height:182px;
  //position: relative;
  //
  //@media screen and (max-width: 768px){
  //  height: max-content;
  //  position: absolute;
  //}
}
.account-number{
  display: flex;
  align-items: center;
  //position: absolute;
  //bottom: 0;
}
.middle-line{
  height: 80%;
  border: none;
  border-radius: 30px;
  border-left: 2.3px solid rgba(79, 79, 79, 0.17);
}
.digits{
  font-family: var(--fontInter);
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: rgba(0, 17, 39, 0.75);
}
.transaction-history{
  background: rgba(253, 255, 252, 0.06);
  border: 1px solid rgba(0, 17, 39, 0.06);
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding: 30px;
}

.transaction-history .v-card{
  box-sizing: border-box;
  width: 320px;
  min-height: 170px;
  height: auto;
  padding: 20px 30px;
   background: var(--offWhite);
  border: 1px solid rgba(0, 17, 39, 0.06);
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.04);

  @media screen and (max-width: 820px) {
    width: 100%;
  }
}
.transaction-history .v-card:hover{
  border: 1px solid var(--offWhite);
}
.credit{
  font-family: var(--fontInter);
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--primaryColor);
}
.debit{
  font-family: var(--fontInter);
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #BA68C8;

}

.reverse{
  font-family: var(--fontInter);
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #BAEEC8;

}

.bonus{
  font-family: var(--fontInter);
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #22DDFF;

}
.question, .date,.time{
  font-family: var(--fontInter);
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #3F4D5D;
}
.answer{
  font-family: var(--fontInter);
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--black);
}
.grouped-cards{
  min-height: 50vh;
  max-height: 50vh;
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;

  @media screen and(max-width: 786px) {
    min-height: max-content;
    max-height: max-content;
  }
}

.grouped-cards::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 2px;
}
.grouped-cards::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 1px !important;
  border-radius: 10px;
}

.withdrawal-icon{
  width: 50px;
  height: 50px;
}

</style>